import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Link } from "gatsby"

const BaguetteEncadrement = (props) => {
  return (
    <>
      <SEO
        title="Baguette d'encadrement pour un cadre sur-mesure"
        description="Large catalogue de baguettes d’encadrement en bois ou en aluminium disponibles dans de nombreux coloris pour vos photographies ou vos toiles"
        canonical={`${process.env.GATSBY_URL_ACTUEL}${props.location.pathname}`}
      />
      <Layout banner={false} location={props.location}>
        <div className="container">
          <h1>Baguette d’encadrement pour un cadre sur-mesure</h1>
          <p>Vous cherchez à réaliser un cadre sur mesure pour l’une de vos photographies ou de vos toiles ?
          Commandez des baguettes d’encadrement afin de réaliser un cadre qui magnifiera votre sujet.</p>
          <p>Nous proposons un <strong>large catalogue de <Link to="/cadres/Cadre-bois/">baguettes d’encadrement en bois</Link> ou en <Link to="/cadres/Cadre-aluminium/">aluminium</Link></strong>
          disponibles dans de nombreux coloris : noir, blanc, or, cuivre, argent, bordeaux, rouge, bleu, violet,
          couleur bois, marron, crème, jaune, gris, orange, vert et rose.</p>
          <h2>Comment choisir vos baguettes d’encadrement ?</h2>
          <p>Découvrez sur notre boutique en ligne notre <strong>panel de <Link to="/cadres/">baguettes d’encadrement</Link></strong> et sélectionnez le
          modèle qui présentera de façon élégante votre photographie ou votre œuvre picturale.</p>
          <p>Selon la matière que vous souhaitez pour réaliser votre encadrement sur mesure, rendez-vous dans
          nos rubriques <Link to="/cadres/Cadre-aluminium/">cadre aluminium</Link> ou <Link to="/cadres/Cadre-bois/">cadre bois</Link>. Cochez la couleur qui s’accordera joliment à votre
          projet puis cliquez sur le modèle de baguette d’encadrement qui vous convient.</p>
          <p>Une fois ces opérations effectuées, nous vous guidons afin de réaliser pour vous un cadre sur
          mesure qui correspondra parfaitement aux dimensions dont vous avez besoin. Ces étapes sont
          simples et ne demandent que peu de temps. Elles vous garantissent un résultat entièrement
          satisfaisant.</p>
          <p>Renseignez les dimensions de votre sujet. Afin de vous faire une idée du rendu, importez une
          photographie de celui-ci et simulez son encadrement dans l’espace correspondant. Il est également
          possible de demander un <strong>échantillon de baguette d’encadrement</strong>. Cela vous permettra de vous
          rendre compte de la qualité des matières et des coloris que nous proposons.</p>
          <p>Sélectionnez ensuite la quantité de baguettes d’encadrement que vous désirez recevoir. Ajoutez la
          commande à votre panier et recevez-la dans un délai de huit jours ouvrés. Pour une livraison rapide,
          en seulement un jour, choisissez l’option EXPRESS !</p>
          <h2>Des baguettes d’encadrement disponibles dans des matières et des coloris variés</h2>
          <p>Nous présentons une très belle sélection de baguettes d’encadrement afin de répondre à toutes vos
          envies et vous offrir ce qui se fait de mieux dans l’art de l’encadrement sur mesure.</p>
          <p>Parmi nos baguettes d’encadrement en bois et en aluminium, disponibles dans seize coloris
          différents, optez pour celles qui se marient parfaitement avec votre sujet. Attention, quelques modèles
          n’existent que dans certaines couleurs.</p>
          <p>Grâce aux baguettes d’encadrement en aluminium, faites réaliser des cadres de faible section en
          grand format. Vous obtiendrez un effet très contemporain, épuré et minimaliste.</p>
          <p>Les baguettes d’encadrement en bois constituent par ailleurs un choix élégant, intemporel et solide
          qui sublimera votre travail. Nous les déclinons sous plusieurs aspects, avec ou sans moulures. Il y a
          en a pour tous les styles, tous les goûts ! Elles peuvent faire jusqu’à 8 cm de largeur, parfait pour
          mettre en valeur aussi bien l’œuvre que le cadre.</p>
          <p>Nous assemblons les baguettes d’encadrement sélectionnées au sein de nos ateliers situés à
          Besançon. Nous expédions votre cadre monté, mais il est possible de commander vos baguettes
          d’encadrement à l’unité afin de réaliser vous-même votre propre encadrement. Vous aurez ainsi la
          satisfaction d’avoir réalisé un travail personnel qui valorise votre savoir-faire. Excellente façon en
          outre d&#39;offrir un superbe cadeau personnalisé.</p>
        </div>
      </Layout>
    </>
  )
}

BaguetteEncadrement.propTypes = {
  identifiants: PropTypes.object,
  location: PropTypes.object,
}

export default BaguetteEncadrement
